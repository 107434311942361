// tabing
.w_tabling {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ededed;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 15px;
    ul {
        list-style: none;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        li {
            padding: 12px 30px;
            font-size: 16px;
            line-height: 18px;
            list-style: none;
            font-weight: 500;
            background-color: #fff;
            cursor: pointer;
            border: 1px solid #ededed;
            border-radius: 5px 5px 0 0;
            margin-right: 10px;
            margin-bottom: -1px;

            &.w_active {
                background-color: #1f8ded;
                color: #fff;
                border-color:#1f8ded;
                border-radius: 5px 5px 0 0;
            }
            
            &:hover {
                background-color: #1f8ded;
                color: #fff;
                border-color:#1f8ded;
                border-radius: 5px 5px 0 0;
            }
        }
    }
}