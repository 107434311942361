// Pagination
.u_pagination {
    display: flex;
    padding: 10px;
    align-items: center;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    height: 50px;

    &-title {
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        margin-right: 5px;
    }

    // Pagination Records
    &-record-wpr {
        width: 70px;
        margin-right: 0;
    }

    .u_record {
        &__control {
            cursor: pointer;
            background-color: #fff;
            border: 1px solid #ededed;
            border-radius: 5px;
            min-height: unset;
            padding: 5px 0;
            box-shadow: none;
            .u_record__placeholder,
            .u_record__single-value,
            .u_record__input {
                font-size: 14px;
                line-height: 16px;
                font-weight: 500;
                margin: 0;
            }

            &:hover {
                border-color: #ededed;
            }
            .u_record__value-container {
                padding: 2px 10px;
            }
        }

        &__indicators {
            display: flex;
            margin-right: 5px;
            .u_record__dropdown-indicator,
            .u_record__clear-indicator {
                background: url('./../images/arrow-down.svg') no-repeat center center;
                width: 14px;
                height: 8px;
                margin: auto 5px 7px;
                padding: 0;
                transform: rotate(180deg);
                svg {
                    display: none;
                }
            }
            .u_record__indicator-separator {
                display: none;
            }
        }

        &__menu {
            z-index: 101;
            margin-bottom: -33px;
            background: #fff;
            border: 1px solid #c2c5d0;
            box-shadow: 0 25px 25px rgba(#000, 0.15);
            border-radius: 5px 5px 0 0;

            .u_record__menu-list {
                padding: 0;
                .u_record__option {
                    font-size: 14px;
                    line-height: 16px;
                    font-weight: 500;
                    text-transform: uppercase;
                    padding: 8px 10px;
                    cursor: pointer;
                    &--is-selected {
                        color: #fff;
                    }
                }
            }
        }
    }

    // Pagination Links
    li {
        list-style: none;
        &.disabled {
            a {
                cursor: not-allowed;
                svg {
                    opacity: 0.3;
                }
            }
        }
    }

    &-link {
        border: 1px solid #ededed;
        border-radius: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        cursor: pointer;
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;

        &-active,
        &:hover {
            background: #000;
            color: #fff;
        }

        &-previous {
            svg {
                transform: rotate(90deg);
            }
            &:hover {
                background: transparent;
            }
        }

        &-next {

            svg {
                transform: rotate(-90deg);
            }
            &:hover {
                background: transparent;
            }
        }

        &-break {
            width: 40px;
        }
    }
}
