.white_bg {
    background-color: #fff;
    border-radius: 5px;
}

.w_flex {
    display: flex;
}
.w_align-items-center {
    align-items: center;
}
.w_content-center {
    justify-content: center;
}
.w_ml-15 {
    margin-left: 15px;
}
.w_mr-15 {
    margin-right: 15px;
}
.w_mb-15 {
    margin-bottom: 15px;
}
.w_mt-15 {
    margin-top: 15px;
}
.w_pt-15 {
    padding-top: 15px;
}
.w_pb-15 {
    padding-bottom: 15px;
}
.w_pl-15 {
    padding-left: 15px;
}
.w_pr-15 {
    padding-right: 15px;
}

.w_ml-20 {
    margin-left: 20px;
}
.w_mr-20 {
    margin-right: 20px;
}
.w_mb-20 {
    margin-bottom: 20px;
}
.w_mt-20 {
    margin-top: 20px;
}
.w_pt-20 {
    padding-top: 20px;
}
.w_pb-20 {
    padding-bottom: 20px;
}
.w_pl-20 {
    padding-left: 20px;
}
.w_pr-20 {
    padding-right: 20px;
}
.w_ml-30 {
    margin-left: 30px;
}
.w_mr-30 {
    margin-right: 30px;
}
.w_mb-30 {
    margin-bottom: 30px;
}
.w_mt-30 {
    margin-top: 30px;
}
.w_pt-30 {
    padding-left: 30px;
}
.w_pb-30 {
    padding-bottom: 30px;
}
.w_pl-30 {
    padding-left: 30px;
}
.w_pr-30 {
    padding-right: 30px;
}
.w_mr-5 {
    margin-right: 5px;
}
.w_ml-5 {
    margin-left: 5px;
}
.w_mt-5 {
    margin-top: 5px;
}
.w_mb-5 {
    margin-bottom: 5px;
}

.w_text-c {
    text-transform: capitalize;
}

.row {
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
    align-items: center;
}
.col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 0 15px;
}

.col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
}

.card-round {
    border: 1px solid black;
        border-radius: 10px;
        padding: 10px;
        margin: 5px;
}

.col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 15px;
}

.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 15px;
}

.col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
}

.w_100 {
    width: 100% !important;
}

.w-color-blue {
    color: #1f8ded;
}

.w_block {
    display: block;
}
