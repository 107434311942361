.table-responsive {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    height: 66vh;
    table {
        caption-side: bottom;
        border-collapse: collapse;
        width: 100%;
        border: 1px solid #ededed;
        th{
           // position: absolute;
            padding: 12px 21px;
        }
        td {
            border-bottom: 1px solid #ededed;
            text-align: left;
            padding: 12px 15px;
            text-transform: capitalize;
        }

        th {
            color: #000;
            font-weight: bold;
            background-color: #f0f0f0;
            position: sticky;
            top: 0;
            z-index: 1;
            min-width: 200px;

            &:first-child {
                min-width: auto;
            }
        }

        td {
            color: #5f5f5f;
            font-weight: 600;
            background: #f7f7f7;

            a {
                text-decoration: none;
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 1.334em;
                color: #000;
            }

            &.w_address {
                max-width: 140px;
            }

            &.w_note {
                max-width: 200px;
                word-break: break-all;
            }
        }
    }
}

.Toastify {
    &__toast-container {
        width: 250px;
        position: fixed;
        right: 0;
        top: 20px;
    }
    &__toast {
        background-color: #fff;
        padding: 10px 30px;
        margin-bottom: 15px;
        border-radius: 5px 0 0 5px;
        transition: 0.5 all;
        text-align: left;

        h2,
        p {
            color: #000;
            margin: 0;
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 18px;
        }
        p {
            color: #888;
            margin-bottom: 0;
            font-size: 14px;
            line-height: 16px;
        }
    }
}

.table-responsive {
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #888 #ededed; /* scroll thumb and track */
}
.table-responsive::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
}

.table-responsive::-webkit-scrollbar-track {
    background: orange; /* color of the tracking area */
}

.table-responsive::-webkit-scrollbar-thumb {
    background-color: blue; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid orange; /* creates padding around scroll thumb */
}

.table-label {
    max-width: 160px;
    height: 27.39px;
    display: inline-block;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    padding: 5px;
    min-width: 65px;
    margin-right: 5px;

    &.blue-label {
        background-color: #1f8ded;
    }

    &.yellow-label {
        background-color: #eab808;
    }

    &.red-label {
        background-color: #d82c0d;
    }

    &.green-label {
        background-color: #00b084;
    }
}
