.w_filters-serach {
    position: relative;

    .form-control {
        padding-right: 40px;
    }
    .w_delete-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        z-index: 1;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        color: hsl(0, 0%, 60%);
        transform: translate(0%, -50%);
        width: 20px;
    }
}