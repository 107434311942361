// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

//-----------------------|| APEXCHART ||-----------------------//

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

//-----------------------|| PERFECT SCROLLBAR ||-----------------------//

.scrollbar-container {
    .ps__rail-y {

        &:hover>.ps__thumb-y,
        &:focus>.ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }

    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container>.ps {
    &.ps--active-y>.ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;

        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }

    &.ps--scrolling-y>.ps__rail-y,
    &.ps--scrolling-x>.ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

//-----------------------|| ANIMATION KEYFRAMES ||-----------------------//

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce {

    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }

    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {

    0%,
    50%,
    100% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-10px);
    }

    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {

    0%,
    50%,
    100% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(10px);
    }
}

.makeStyles-drawerPaper-142,
.makeStyles-drawer-141,
.makeStyles-drawer-235,
.makeStyles-drawerPaper-236 {
    width: 250px !important;
}

.css-kvip5o-MuiToolbar-root {
    min-height: 88px !important;
    border-bottom: 1px solid #ededed !important;
}

.makeStyles-content-217 {
    width: calc(100% - 250px) !important;
}

.css-12ztel5-MuiButtonBase-root-MuiListItemButton-root {
    border-radius: 5px !important;

    .css-19u81b4-MuiTypography-root,
    .css-1159lsg-MuiTypography-root {
        font-weight: 600 !important;
    }
}

.w_error {
    color: red;
}

.w_logo-img {
    font-size: 16px;
    color: #000;
    line-height: 50px;
    font-weight: 600;

    .w_img {
        border: 1px solid #ededed;
        border-radius: 5px;
        height: 50px;
        display: flex;
        align-content: center;
        padding: 0 12px;
        width: 50%;
        text-align: left;
    }

    &-close {
        margin-left: 10px;
        cursor: pointer;
        width: 20px;
        height: 20px;
        color: #fff;
        background-color: #000;
        border-radius: 100px;
        display: flex;
        font-size: 12px;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        transition: 0.5s all;

        &:hover {
            background-color: #1f8ded;
            color: #fff;
        }
    }
}

/* common css */

@import '../scss/common-class.scss';
@import '../scss/table.scss';
@import '../scss/radio.scss';
@import '../scss/input.scss';
@import '../scss/button.scss';
@import '../scss/tab.scss';
@import '../scss/template.scss';
@import '../scss/filter.scss';
@import '../scss/paginations.scss';
@import '../scss/side-modal';

.w_product-icon {
    cursor: pointer;
    height: 50px;
    width: 50px;
    border-radius: 5px;
    border: 1px solid #ededed;
    // margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 22px;
    }

    &:hover {
        background-color: #8883;
    }
}

.w_next-icon {
    cursor: pointer;
    height: 50px;
    width: 50px;
    border-radius: 5px;
    border: 1px solid #ededed;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 22px;
    }

    &:hover {
        background-color: #8883;
    }
}

.w_print-icon {
    cursor: pointer;
    height: 50px;
    width: 50px;
    border-radius: 5px;
    border: 1px solid #ededed;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 22px;
    }

    &:hover {
        background-color: #8883;
    }
}


.w_export-icon {
    cursor: pointer;
    height: 50px;
    width: 60px;
    border-radius: 5px;
    border: 1px solid #ededed;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 20px;
    }

    &:hover {
        background-color: #8883;
    }
}

.flex-shrink {
    flex-shrink: 0;
}

.w_sorting {
    margin-left: 8px;
    width: 20px;
    height: auto;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: pointer;

    img {
        width: 15px;
    }
}

.w_loder {
    position: fixed;
    z-index: 9999 !important;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    span {
        width: 40px;
        height: 40px;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
    }
}