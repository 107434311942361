
.w-btn {
    height: 50px;
    box-shadow: none !important;
    border-radius: 4px;
    background-color: #1f8ded;
    text-transform: capitalize;
    text-align: center;
    width: 120px;
    outline: none !important;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    cursor: pointer;
    box-shadow: none;
    transition: all 0.5s;
    border: none;

    &-blue {
        background-color: #1f8ded;   
        color: #fff; 

        &-only {
            background-color: #f7f7f7  !important;   
            color: #000 !important;
            
        }
    }

    &-cancel {
        background-color: #474747;
        color: #fff; 
    }

    &-green {
        background-color: #00b084;
        color: #fff; 
    }

    &-danger {
        background-color: #d82c0d;
        color: #fff; 
    }

    &.w-active {
        background-color: #1f8ded !important;
        color: #fff !important;
    }

    &:hover {
        background-color: #000;
        color: #fff;
    }
}

.w-table-button {
    height: 30px;
    font-weight: 600;
    border-radius: 50px;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
    outline: none;
    border: none;
    width: 130px;
    cursor: pointer;
    background-color: #1f8ded;   
    color: #fff; 
    box-shadow: none;
    transition: all 0.5s;

    &-save {
        background-color: #00b084;
    }

    &-danger {
        background-color: #d82c0d;
    }

    &-yellow {
        background-color: #EAB808;
    }

    &-green {
        background-color: #00b084;
    }

    &:hover {
        background-color: #000;
        color: #fff;
    }
}


.css-11bu8qf-MuiPaper-root-MuiCard-root {
    min-height: 86vh;
}