.u_side-modal {
	top: -100%;
	position: fixed;
	transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
	position: fixed;
	&.active {
		top: 0;
		left: 0;
		z-index: 101;
		width: 100%;
		height: 100%;
		display: flex;
		transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
	}

	// Sidebar-Modal
	&.u_sidebar-modal {
		top: 0;
		position: inherit;
		transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
		position: unset;
		.u_side-modal-layout {
			position: absolute;
			right: -100%;
			left: unset;
			transform: translateX(-100%);
			top: 60px;
			height: 100%;
			max-height: calc(100% - 60px);
			border-radius: 5px 0 0 0;

			// @include mediaQuery($desktop) {
			// 	top: 66px;
			// 	max-height: calc(100% - 66px);
			// }

			.u_side-modal-body {
				max-height: calc(100vh - 124px) !important;

				// @include mediaQuery($desktop) {
				// 	max-height: calc(100vh - 110px) !important;
				// }
			}
		}

		&.active {
			position: fixed;
			.u_side-modal-layout {
				transform: translateX(0);
				right: 0;
				transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
			}
		}
	}

	&-layout {
		display: flex;
		flex-direction: column;
		z-index: 101;
		width: 95%;
		max-width: 900px;
		background: #fff;
		border: 1px solid #ededed;
		border-radius: 5px;
		box-shadow: -11px 0px 30px rgba(30, 37, 56, 0.06);
		max-height: calc(100% - 30px);
		margin: auto;
	}

	&-header {
		background: #f9f9f9;
		border-bottom: 1px solid #ededed;
		padding: 15px 20px;
		flex: 0 0 auto;
		// @include mediaQuery ($desktop) {
		// 	padding: 10px 20px;			
		// }
		h4 {
			font-size: 16px;
			margin: 0;
			// @include mediaQuery ($desktop) {  
			// 	@include mediaFontStyle(16, 18);
			// }
		}
	}

	&-body {
		flex: 0 1 100%;
		overflow-y: auto;
	}

	&-footer {
		padding: 10px 0;
		border-top: 1px solid #888;
		flex: 0 0 auto;
		&:empty {
			display: none;
		}
	}
}


.u_modal-overlay {
	position: fixed;
	background:rgba(30, 37, 56, 0.06);
	width: 100%;
	height: 100%;
}

.w_cp {
cursor: pointer;
}