.w_label {
    font-size: 14px;
    line-height: 16px;
    color: #000;
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
}

.form-control {
    box-shadow: none;
    height: 50px;
    padding: 12px 15px;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    font-weight: 500;
    width: 100%;
    border-radius: 5px;
    // outline: none;
    background: #f7f7f7;
    border: 1px solid #ededed;
    cursor: auto;
    -webkit-appearance: none;
    background-image: none;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;
    resize: none;

    &:focus,
    &:focus-within,
    &:hover {
        box-shadow: 0 0 0 2px #1f8ded;
        outline-color: #1f8ded;
    }

    &-table {
        height: 30px;
        box-shadow: none !important;
        background-color: #fff;
        outline: none !important;
        padding: 5px 12px;
        font-size: 12px;
        line-height: 14px;
    }

    &-table-textarea {
        box-shadow: none !important;
        background-color: #fff;
        outline: none !important;
        padding: 8px 12px;
        font-size: 12px;
        line-height: 14px;
        resize: vertical;
        min-height: 70px;
    }
}

.w_select {
    z-index: 9;
    &__control {
        height: 50px;
        background-color: #f7f7f7 !important;
        border: 1px solid #ededed !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;

        &--is-focused {
            box-shadow: 0 0 0 2px #1f8ded;
        }

        &:focus,
        &:focus-within,
        &:hover {
            box-shadow: 0 0 0 2px #1f8ded;
            outline-color: #1f8ded;
        }
    }

    &__value-container {
        padding: 12px;
    }

    &__indicator {
        cursor: pointer;
    }

    &__single-value {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }
}

.w_fileupload #fileupload {
    box-shadow: none;
    height: 50px;
    padding: 12px;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    font-weight: 500;
    width: 100%;
    border-radius: 5px;
    outline: none;
    background-color: #f7f7f7;
    border: 1px solid #ededed;
    cursor: auto;
}
