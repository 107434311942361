/* Customize the label (the w_checkbox) */
.w-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 15px;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 20px;
}

/* Hide the browser's default radio button */
.w-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.w-radio {
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        border-radius: 50%;
    }
}

/* On mouse-over, add a grey background color */
.w-radio:hover input ~ .checkmark {
    background-color: #ccc;
}

.w-radio input:checked {
    color: #1f8ded;
}
/* When the radio button is checked, add a blue background */
.w-radio input:checked ~ .checkmark {
    background-color: #1f8ded;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.w-radio {
    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }
}

/* Show the indicator (dot/circle) when checked */
.w-radio input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.w-radio .checkmark:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: white;
}

// Check-Box
/* The w_checkbox */
.w_checkbox {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    display: inline list-item;
}

/* Hide the browser's default w_checkbox */
.w_checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom w_checkbox */
.w_checkbox {
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border: 1px solid #ededed;
        border-radius: 5px;
    }
}

/* On mouse-over, add a grey background color */
.w_checkbox:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the w_checkbox is checked, add a blue background */
.w_checkbox input:checked ~ .checkmark {
    background-color: #1f8ded;
    border-color: #1f8ded;
}

/* Create the checkmark/indicator (hidden when not checked) */
.w_checkbox {
    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }
}

/* Show the checkmark when checked */
.w_checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.w_checkbox .checkmark:after {
    left: 6px;
    top: 1px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-top-width: medium;
    border-right-width: medium;
    border-bottom-width: medium;
    border-left-width: medium;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
